<template>
    <div id="contentwrap">
        <PageTitle />
        <div v-if="loading">
            <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
        </div>
        <article v-else class="row  removePosition">
            <div class="inner">
                <!-- Article Header -->
                <header>
                    <h1 v-if="data.Title" class="title">{{data.Title}}</h1>
                    <div class="meta">
                        <div class="tags">
                            <span v-if="data.Area">{{data.Area}}</span>
                            <span v-if="data.ZipCode">, {{data.ZipCode}}</span>
                            <br v-if="data.Area || data.ZipCode">
                            <span v-if="(data.Price && data.Status !== 'sold') || data.OfferedPrice">
                                Offered at
                            </span>
                            <span v-if="data.OfferedPrice">
                                {{data.OfferedPrice | priceFormat}}
                            </span>
                            <span v-else-if="(data.Price && data.Status !== 'sold') && !data.Lease">
                                {{data.Price | priceFormat}}
                            </span>
                            <span v-else-if="(data.Price && data.Status !== 'sold') && data.Lease">
                                {{data.Price | priceFormat}}/mo.
                            </span>
                            <br v-if="(data.Price && data.Status !== 'sold') || data.OfferedPrice">
                            <div v-if="data.Price && data.Status === 'sold' && data.OfferedPrice">
                                <br>
                                    <strong>
                                        <span v-if="data.Price && data.Status === 'sold'">
                                        Sold for
                                        </span>
                                        <span v-if="data.Price">
                                            {{data.Price | priceFormat}}
                                        </span>
                                        <span v-if="data.SoldDate">
                                            in {{data.SoldDate}}
                                        </span>
                                    </strong>
                                <br>
                            </div>
                            <div v-else-if="data.Price && data.Status === 'sold' && !data.Lease">
                                <br>
                                <span v-if="data.Price && data.Status === 'sold'">
                                Sold for
                                </span>
                                <span v-if="data.Price">
                                    {{data.Price | priceFormat}}
                                </span>
                                <span v-if="data.SoldDate">
                                    in {{data.SoldDate}}
                                </span>
                            </div>
                            <div v-else-if="data.Price && data.Status === 'sold' && data.Lease">
                                <br>
                                <span>
                                Leased for
                                </span>
                                <span v-if="data.Price">
                                    {{data.Price | priceFormat}}/mo.
                                </span>
                                <span v-if="data.SoldDate">
                                    in {{data.SoldDate}}
                                </span>
                            </div>
                            <br v-if="data.Beds || data.Baths || data.Size || data.LotSize">
                            <span v-if="data.Beds">
                                {{data.Beds}} bed
                            </span>
                            <span v-if="data.Beds && (data.Baths || data.Size || data.LotSize)">
                                | 
                            </span>
                            <span v-if="data.Baths">
                                {{data.Baths}} bath
                            </span>
                            <span v-if="data.Baths && (data.Size || data.LotSize)">
                                | 
                            </span>
                            <span v-if="data.Size">
                                {{data.Size}} sf.
                            </span>
                            <span v-if="data.Size && data.LotSize">
                                | 
                            </span>
                            <span v-if="data.LotSize">
                                {{data.LotSize}} sf. lot
                            </span>
                            <br v-if="data.AdditionalInfo">
                            <span v-if="data.AdditionalInfo">
                                {{data.AdditionalInfo}}
                            </span>
                        </div>
                    </div>
                </header>
                <div class="col span3of3 textleft">
                    <div v-if="data.VimeoVideoUrl" class="video-container">
                        <iframe :src="`//player.vimeo.com/video/${data.VimeoVideoUrl}`" width="800" height="450" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                    </div>
                    <markdown-it-vue class="md-body" :content="data.Description" :options="options"/>
                    <div v-if="data.Carousel.length === 0 && data.DisplayImage" class="col span3of3 textleft">
                        <img style="width:100%;height:auto;" :src="url+data.DisplayImage.url">
					</div>
                    <Album v-if="data.Carousel.length > 1" v-bind:images="data.Carousel" />
                </div>
                <p>
                    <br>
                    <a v-if="data.ViewOnGoogleMap" :href="data.ViewOnGoogleMap" alt="Link to Google Maps" target="_blank">
                        <u>See on Google Maps</u>
                    </a>
                </p>
                <div v-html="data.AdditionalIframe">
                </div>
            </div>
        </article>
    </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
import PageTitle from "../components/PageTitle"
import Album from "../components/Album.vue"
import MarkdownItVue from 'markdown-it-vue'
import 'markdown-it-vue/dist/markdown-it-vue.css'

export default {
    name: "property",
    components: {
        PageTitle,
        Spinner,
        Album,
        MarkdownItVue
    },
    data(){
        return{
            loading: true,
            data: {},
            title: 'Property',
            url: 'https://strapi.uptowncreative.io',
            options: {
                markdownIt: {
                    linkify: true
                },
                linkAttributes: {
                    attrs: {
                    target: '_blank',
                    rel: 'noopener'
                    }
                }
            }
        }
    },
    mounted:function(){
        this.axios.get(`https://strapi.uptowncreative.io/meredith-schlosser-properties?Slug=${this.$route.params.slug}`)
        .then((response) => {
            this.data = response.data[0];
            this.title = this.data.Title;
            this.loading = false;
        })
    },
    metaInfo() {
         let data = this.title; 
         return {
             title: data ? data : "Property",
             titleTemplate: '%s | Meredith Schlosser',
                meta: [{
                    name: 'description', 
                    content: `Meredith Schlosser - Realtor. ${data} Pictures and description inside.`
                },
                {
                    name: 'author', 
                    content: "Meredith Schlosser"
                },]
         }
    },
}
</script>

<style lang="scss" scoped>
#contentwrap, .removePosition{
  position: unset !important;
}

.md-body{
    margin: 20px 0;
}
</style>